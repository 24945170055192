import "./hangzhou"
const points = [
    {
        name: '杭州市统战之家',
        value: [120.191828, 30.251707],
        address: '浙江省杭州市上城区中河南路11号',
        img: require("../assets/home.png")
    }
]
let option = {
    backgroundColor: 'rgb(255, 255, 255, 0)',
    visualMap: {
        show: false,
        inRange: {
            color: ['#7fccff', '#0042a3']
        }
    },
    tooltip: {
        show: true,
    },
    geo: {
        map: '杭州',
        left: 152, top: 32, right: 232, bottom: 116,
        label: {
            emphasis: {
                show: false
            }
        },
    },
    series: [
        {
            name: '区域分布',
            type: 'map',
            mapType: '杭州',
            left: 152, top: 32, right: 232, bottom: 116,
            label: {
                show: true,
                color: '#fff',
                fontSize: 10,
                formatter: params => {
                    return params.data ? params.name + " " + params.data.value : params.name;
                }
            },
            itemStyle: {
                normal: {
                    areaColor: '#7fbdff',
                    borderColor: '#2573bf'
                },
                emphasis: {
                    label: {
                        show: false
                    },
                    areaColor: '#7fbdff'
                }
            },
            tooltip: {
                show: false
            },
            data: []
        },
        {
            type: 'scatter',
            coordinateSystem: 'geo',
            symbol: "image://" + require("../assets/point.png"),
            symbolSize: 24,
            label: {
                show: false
            },
            tooltip: {
                backgroundColor: 'rgba(50,50,50,0)',
                borderWidth: 0,
                formatter: params => {
                    let name = params.name;
                    let item = points.find(p => p.name == name);
                    return '<div class="map-tip">' +
                        '<div class="map-tip-title">' + name + '</div>' +
                        '<div class="map-tip-addr">' +
                        '<div class="map-tip-addr-title">地址：</div>' +
                        '<div class="map-tip-addr-text">' + item.address + '</div>' +
                        '</div>' +
                        '<div class="map-tip-img" style="background-image: url(' + item.img + ')"></div>' +
                        '</div>'
                },
            },
            data: points.map(item => {
                return {
                    name: item.name,
                    value: item.value
                }
            })
        }
    ]
};
export default option
