<template>
    <div class="grid-box">
        <div class="corner top-left"></div>
        <div class="corner top-right"></div>
        <div class="corner bottom-right"></div>
        <div class="corner bottom-left"></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "grid-box"
    }
</script>

<style lang="less">
    .grid-box {
        position: relative;
        padding: 16px;
        background: rgba(35,219,252,.05) url(../assets/grid.png) center;
        box-shadow: inset 0 0 12px 1px rgba(16,173,255,.35);
    }
    .corner {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        &.top-left {
            top: 8px;
            left: 8px;
            border-color: #1db4d5 transparent transparent #1db4d5;
        }
        &.top-right {
            top: 8px;
            right: 8px;
            border-color: #1db4d5 #1db4d5 transparent transparent;
        }
        &.bottom-right {
            right: 8px;
            bottom: 8px;
            border-color: transparent #1db4d5 #1db4d5 transparent;
        }
        &.bottom-left {
            left: 8px;
            bottom: 8px;
            border-color: transparent transparent #1db4d5 #1db4d5;
        }
    }
</style>
