const color = ['#00aeef', '#f35958', '#f6d052', '#a763a5'];
let option = {
    tooltip: {
        show: false
    },
    grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            data: ['专科及以下', '本科', '硕士', '博士'],
            nameTextStyle: {
                color: params => {
                    return color[params.dataIndex];
                },
            },
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false
            },
        }
    ],
    yAxis: [
        {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false
            },
        }
    ],
    series: [
        {
            name: '数量',
            type: 'bar',
            barWidth: '50%',
            barGap: '-100%',
            itemStyle: {
                color: params => {
                    return color[params.dataIndex];
                },
            },
            data: [5380, 5339, 41, 2324]
        },
        {
            name: '背景',
            type: 'bar',
            barWidth: '50%',
            barGap: '-100%',
            itemStyle: {
                borderRadius: [2, 2, 0, 0]
            },
            data: []
        }
    ]
};

export {color};
export default option;
