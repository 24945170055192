export default {
    grid: {
        top: 20,
        left: 0,
        right: 10,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        data: ['入会申请', '场地预约', '活动申请', '名师预约', '献言献策'],
        axisLabel: {
            color: '#13d1f9',
            fontSize: 12
        },
        axisTick: {
            show: false
        },
        axisLine: {
            show: false
        }
    },
    yAxis: {
        axisLabel: {
            margin: 6,
            color: '#13d1f9',
            fontSize: 12
        },
        axisTick: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: '#084891'
            }
        },
        splitArea: {
            show: true,
            areaStyle: {
                color: ['rgba(18,47,92,0.6)', 'rgba(18,47,92,0)']
            }
        }
    },
    series: [
        {
            type: 'bar',
            barWidth: '25%',
            itemStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {offset: 0, color: '#13d5fc'}, // 0% 处的颜色
                        {offset: 1, color: 'rgba(32,89,184,.7)'} // 100% 处的颜色
                    ],
                }
            },
            data: [0,0,0,0,0]
        }
    ]
}
