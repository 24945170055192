import pie from './pie';
import {clone} from "../common/js/tool";
let activity = clone(pie);
activity.name = '活动举办';
activity.series[0].startAngle = -60;
activity.series[0].data = [
    {value: 0, name: '官方'},
    {value: 0, name: '个人'},
]
export default activity;
