import pie from './pie';
import {clone} from "../common/js/tool";
let age = clone(pie);
age.name = '年龄分布';
age.series[0].data = [
    {value: 15, name: '18-25'},
    {value: 40, name: '26-35'},
    {value: 20, name: '36-45'},
    {value: 25, name: '45-50'},
];
export default age;
