const color = ['#00aeef', '#f35958', '#f6d052', '#a763a5', '#fbe0c2'];
export default {
    color: color,
    series: [
        {
            name: '场地用途',
            type: 'pie',
            radius: '70%',
            center: ['50%', '50%'],
            roseType: 'radius',
            zlevel: 1,
            startAngle: 60,
            label: {
                formatter: '{c|{d}%\n{b}}',
                color: '#64fdfd',
                fontSize: 12,
                rich: {
                    c: {
                        align: 'center',
                    }
                }
            },
            labelLine: {
                lineStyle: {
                    cap: 'round'
                }
            },
            data: [
                {value: 0, name: '学习'},
                {value: 0, name: '接待'},
                {value: 0, name: '活动'},
                {value: 0, name: '开会'},
                {value: 0, name: '其他'},
            ]
        },
        {
            name: '背景',
            type: 'pie',
            radius: ['70%', '90%'],
            center: ['50%', '50%'],
            label: {
                show: false
            },
            itemStyle: {
                color: {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [{
                        offset: 0, color: 'rgba(61, 211, 254, 0)' // 0% 处的颜色
                    },{
                        offset: 0.8, color: 'rgba(61, 211, 254, 0)' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'rgba(61, 211, 254, 0.5)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },
            },
            data: [{
                value: 100
            }]
        },
    ]
}
