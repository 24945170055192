<template>
    <div class="screen-area">
        <div class="screen">
            <div class="screen-top">
                <div class="top-side before"></div>
                <div class="top-title">“杭统云E家”可视化数据平台</div>
                <div class="top-side after"></div>
            </div>
            <div class="screen-mid">
                <div class="screen-left">
                    <grid-box class="left-top">
                        <div class="left-top-box">
                            <div class="box-title">
                                <div class="box-title-icon"></div>
                                <div class="box-title-text">性别分布</div>
                            </div>
                            <div class="left-top-box-chart" id="sex_chart"></div>
                        </div>
                        <div class="line-gap"></div>
                        <div class="left-top-box">
                            <div class="box-title">
                                <div class="box-title-icon"></div>
                                <div class="box-title-text">年龄分布</div>
                            </div>
                            <div class="left-top-box-chart" id="age_chart"></div>
                        </div>
                    </grid-box>
                    <grid-box class="left-center">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">学历分布</div>
                        </div>
                        <div class="left-center-chart" id="edu_chart"></div>
                        <div class="edu-data">
                            <div class="edu-data-item" v-for="(w, i) in eduData" :key="i">
                                <div class="edu-item-ti" :style="{color: w.color}">{{w.name}}</div>
                                <div class="edu-item-num">
                                    <span v-for="(n, j) in w.num + ''" :key="j">{{n}}</span>
                                </div>
                            </div>
                        </div>
                    </grid-box>
                    <grid-box class="left-bottom">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">活动轨迹</div>
                        </div>
                        <transition-group class="act-locus" name="up" tag="div">
                            <div class="act-locus-item" v-for="act in actList" :key="act.key">
                                <div class="act-locus-img" :style="{backgroundImage: `url(${act.cover})`}"></div>
                                <div class="act-locus-title">{{act.name}}</div>
                            </div>
                        </transition-group>
                    </grid-box>
                </div>
                <div class="screen-center">
                    <div class="center-top">
                        <grid-box class="center-top-box box-left">
                            <div class="box-title">
                                <div class="box-title-icon"></div>
                                <div class="box-title-text">统战之家</div>
                            </div>
                            <div class="center-top-box-num">20个</div>
                        </grid-box>
                        <grid-box class="center-top-box box-right">
                            <div class="box-title">
                                <div class="box-title-icon"></div>
                                <div class="box-title-text">统战团体数量</div>
                            </div>
                            <div class="center-top-box-num">7个</div>
                        </grid-box>
                    </div>
                    <div class="center-mid">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">杭州市统战之家区域分布</div>
                        </div>
                        <div class="area-chart" id="area_chart"></div>
                    </div>
                    <grid-box class="center-bottom">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">审批统计</div>
                        </div>
                        <div class="center-bottom-chart" id="approve_chart"></div>
                    </grid-box>
                </div>
                <div class="screen-right">
                    <grid-box class="right-top">
                        <div class="right-top-box">
                            <div class="right-top-box-piece">
                                <div class="box-title">
                                    <div class="box-title-icon"></div>
                                    <div class="box-title-text">政策发布</div>
                                </div>
                                <div class="right-top-box-text">{{source.zcfb}}篇</div>
                            </div>
                            <div class="right-top-box-piece">
                                <div class="box-title">
                                    <div class="box-title-icon"></div>
                                    <div class="box-title-text">参观人次</div>
                                </div>
                                <div class="right-top-box-text">{{source.cgrs}}人次</div>
                            </div>
                        </div>
                        <div class="right-top-box">
                            <div class="box-title">
                                <div class="box-title-icon"></div>
                                <div class="box-title-text">活动举办</div>
                            </div>
                            <div class="activity-chart" id="activity_chart"></div>
                        </div>
                    </grid-box>
                    <grid-box class="right-center">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">场地用途</div>
                        </div>
                        <div class="use-chart" id="use_chart"></div>
                    </grid-box>
                    <grid-box class="right-bottom">
                        <div class="box-title">
                            <div class="box-title-icon"></div>
                            <div class="box-title-text">同心公益直播</div>
                        </div>
                        <div class="live-list">
                            <table>
                                <thead>
                                <tr>
                                    <th>直播专题</th>
                                    <th>直播时间</th>
                                    <th>观看人数</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :class="{back: i % 2 == 0}" v-for="(l, i) in liveList" :key="i">
                                    <td>{{l.title}}</td>
                                    <td>{{l.time}}</td>
                                    <td>{{l.num}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </grid-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import gridBox from "../components/grid-box";
    import sex from "../charts/sex";
    import age from "../charts/age";
    import activity from "../charts/activity";
    import use from "../charts/use";
    import education, {color} from "../charts/education";
    import approve from "../charts/approve";
    import hz from "../charts/hz";
    import {getDate} from "../common/js/tool";

    const chartLoadingOpt = {
        text: '',
        color: '#0254ca',
        textColor: '#fff',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0,
        // 字体大小。从 `v4.8.0` 开始支持。
        fontSize: 18,
        // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        showSpinner: true,
        // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        spinnerRadius: 16,
        // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        lineWidth: 2,
        // 字体粗细。从 `v5.0.1` 开始支持。
        fontWeight: 'normal',
        // 字体风格。从 `v5.0.1` 开始支持。
        fontStyle: 'normal',
        // 字体系列。从 `v5.0.1` 开始支持。
        fontFamily: 'sans-serif'
    }
    const charts = ['sex', 'age', 'edu', 'approve', 'activity', 'use', 'area'];
    export default {
        name: "Screen",
        components: {
            gridBox
        },
        data() {
            return {
                eduData: [],
                actList: [],
                liveList: [],
                source: {
                    zcfb: 0,
                    cgrs: 0
                },
                actIndex: 0
            }
        },
        mounted() {
            this.getData();
        },
        beforeDestroy() {
            if(this.actTimer) {
                clearInterval(this.actTimer);
                this.actTimer = null;
            }
        },
        methods: {
            getData() {
                const url = "/official/get-data";
                this.$axios(url).then(res => {
                    this.source = res;
                    this.setData();
                })
            },
            setData() {
                this.source.hdsj.forEach((item, i) => {
                    item.key = i;
                    item.img = item.img ? item.img.split(",") : [];
                    item.cover = item.img[0];
                });
                this.setActList();
                this.liveList = this.source.stream.map(item => {
                    return {
                        title: item.title,
                        time: getDate(item.start_time).pattern("yyyy-MM-dd HH:mm"),
                        num: item.viewCount
                    }
                });
                this.initCharts();
            },
            setActList() {
                const actList = this.source.hdsj;
                if(actList.length > 2) {
                    this.actList = actList.slice(this.actIndex, this.actIndex + 2);
                    this.actIndex += 2;
                    const _this = this;
                    this.actTimer = setInterval(() => {
                        let list = actList.slice(_this.actIndex, _this.actIndex + 2);
                        if(list.length < 2) {
                            _this.actIndex = list.length;
                            list = list.concat(actList.slice(0, 2 - list.length));
                        } else {
                            _this.actIndex += 2;
                        }
                        _this.actList = list;
                    }, 5000);
                } else {
                    this.actList = actList;
                }
            },
            initCharts() {
                charts.forEach(item => {
                    const key = `${item}Chart`;
                    if(!this[key]) {
                        this[key] = echarts.init(document.getElementById(`${item}_chart`));
                        this[key].showLoading('default', chartLoadingOpt);
                    }
                })
                this.setCharts();
            },
            setCharts() {
                charts.forEach(item => {
                    let str = item.replace(/( |^)[a-z]/g, l => l.toUpperCase());
                    let fn = this[`set${str}Chart`];
                    fn();
                })
            },
            setSexChart() {
                this.sexChart.setOption(sex);
                this.sexChart.hideLoading();
            },
            setAgeChart() {
                this.ageChart.setOption(age);
                this.ageChart.hideLoading();
            },
            setEduChart() {
                let max = education.series[0].data.reduce((acc, val) => acc * 1 + val * 1);
                this.eduData = education.xAxis[0].data.map((item, i) => {
                    return {
                        name: item,
                        color: color[i],
                        num: education.series[0].data[i]
                    }
                });
                education.series[1].data = education.series[0].data.map((item, i) => {
                    return {
                        value: max,
                        name: education.xAxis[0].data[i],
                        itemStyle: {
                            color: 'transparent',
                            borderColor: color[i],
                            borderWidth: 1
                        }
                    }
                });
                this.eduChart.setOption(education);
                this.eduChart.hideLoading();
            },
            setApproveChart() {
                const sp = this.source.sp;
                if(sp) {
                    approve.series[0].data = [sp.rhsq, sp.cdyy, sp.hdsq, sp.msyy, sp.xyxc]
                }
                this.approveChart.setOption(approve);
                this.approveChart.hideLoading();
            },
            setActivityChart() {
                const actData = this.source.hdjb;
                if(actData) {
                    activity.series[0].data[0].value = actData.gf;
                    activity.series[0].data[1].value = actData.gr;
                }
                this.activityChart.setOption(activity);
                this.activityChart.hideLoading();
            },
            setUseChart() {
                const useData = this.source.cdyt;
                if(useData) {
                    use.series[0].data[0].value = useData.xx;
                    use.series[0].data[1].value = useData.jd;
                    use.series[0].data[2].value = useData.hd;
                    use.series[0].data[3].value = useData.kh;
                    use.series[0].data[4].value = useData.qt;
                }
                this.useChart.setOption(use);
                this.useChart.hideLoading();
            },
            setAreaChart() {
                this.areaChart.setOption(hz);
                this.areaChart.hideLoading();
            }
        }
    }
</script>

<style scoped lang="less">
    .screen-area {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #051a3b;
        box-shadow: inset 0 0 10px rgba(35,159,240,.35);
        z-index: 11;
        overflow: auto;
        --gap: 24px;
        --half-gap: 12px;
        --main-color: #64fdfd;
        color: #fff;
    }
    .screen {
        display: flex;
        flex-direction: column;
        padding: 0 10px 10px;
        width: 1920px;
        height: 1080px;
        transform-origin: 0 0;
    }
    .screen-top {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 136px;
        background-image: linear-gradient(0, rgba(35,159,240,0), rgba(35,159,240,.6));
    }
    .top-title {
        flex-shrink: 0;
        margin: 0 8px;
        padding: 0 48px;
        height: 80px;
        background-image: linear-gradient(0, #003f8e, #002662);
        border: 1px solid #074b78;
        box-shadow: inset 0 0 10px rgba(35,159,240,.35);
        color: #fff;
        line-height: 78px;
        font-size: 46px;
    }
    .top-side {
        flex: 1;
        height: 100%;
        background: url(../assets/title-side.png) center no-repeat;
        background-size: contain;
        &.after {
            transform: rotateZ(180deg);
        }
    }
    .screen-mid {
        flex: 1;
        display: flex;
        padding: 0 var(--gap) var(--gap);
    }
    .screen-left,
    .screen-right {
        display: flex;
        flex-direction: column;
        width: 430px;
    }
    .screen-center {
        flex: 1;
        display: flex;
        flex-direction: column;
        .center-top {
            display: flex;
        }
        .center-top-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 136px;
            &.box-right {
                margin-left: var(--gap);
            }
        }
        .center-mid {
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 16px;
            margin-top: var(--gap);
            background: rgba(35,219,252,.05) url(../assets/grid.png) center;
            box-shadow: inset 0 0 12px 1px rgba(16,173,255,.35);
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url(../assets/boder.png) center;
                background-size: 100% 100%;
            }
            .box-title {
                position: absolute;
                top: 16px;
                left: 16px;
            }
        }
        .center-bottom {
            display: flex;
            flex-direction: column;
            margin-top: var(--gap);
            height: 236px;
        }
    }
    .screen-left {
        .left-top {
            display: flex;
        }
        .grid-box {
            flex: 1;
        }
        .left-center,
        .left-bottom {
            margin-top: var(--gap);
        }
    }
    .screen-right {
        .grid-box {
            flex: 1;
        }
        .right-center,
        .right-bottom {
            margin-top: var(--gap);
        }
    }
    .screen-center {
        margin: 0 var(--gap);
    }
    .box-title {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 0 12px;
        color: var(--main-color);
        line-height: 1;
    }
    .box-title-icon {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 2px solid var(--main-color);
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: var(--main-color);
        }
    }
    .box-title-text {
        margin-left: 6px;
        font-size: 24px;
    }
    .left-top-box {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .left-top-box-chart {
        flex: 1;
    }
    .left-center {
        display: flex;
        flex-direction: column;
    }
    .left-center-chart {
        flex: 1;
    }
    .edu-data {
        display: flex;
        align-items: center;
        padding: 10px 0;
        height: 82px;
    }
    .edu-data-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #fff;
    }
    .edu-item-ti {
        font-size: 16px;
    }
    .edu-item-num {
        margin-top: 14px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        span {
            display: inline-block;
            width: 16px;
            height: 24px;
            border-radius: 2px;
            background: linear-gradient(0deg, #2c5aca, #2c5aca 48%, transparent 48%, transparent 52%, #2c5aca 52%, #2c5aca);
            &:not(:first-of-type) {
                margin-left: 3px;
            }
        }
    }
    .act-locus {
        position: relative;
    }
    .act-locus-item {
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding: 12px 16px;
        background-color: rgba(18,47,92,.8);
        transition: all .5s;
    }
    .act-locus-img {
        flex-shrink: 0;
        width: 124px;
        height: 74px;
        background: url(../assets/home.png) center no-repeat;
        background-size: cover;
    }
    .act-locus-title {
        flex: 1;
        min-width: 0;
        max-height: 74px;
        padding-left: 16px;
        font-size: 16px;
        line-height: 20px;
    }
    .center-top-box-num {
        font-size: 48px;
        font-weight: bold;
    }
    .center-bottom-chart {
        flex: 1;
    }
    .right-top {
        display: flex;
    }
    .right-top-box {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .right-top-box-piece {
        flex: 1;
    }
    .right-top-box-text {
        margin-top: 12px;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
    }
    .activity-chart {
        flex: 1;
    }
    .right-center {
        display: flex;
        flex-direction: column;
    }
    .use-chart {
        flex: 1
    }
    .live-list {
        margin-top: 12px;
        text-align: center;
        table {
            width: 100%;
        }
        thead {
            font-size: 18px;
            line-height: 46px;
        }
        tbody {
            font-size: 14px;
            line-height: 26px;
            tr.back {
                background-color: rgba(18,47,92,0.6);
            }
        }
    }
    .area-chart {
        flex: 1;
        background: url(../assets/map-base.png) center bottom no-repeat;
        background-size: 470px 178px;
    }

    .up-enter {
        opacity: 0;
        transform: translateY(100%);
    }
    .up-leave-to {
        opacity: 0;
        transform: translateY(-100%);
    }
    .up-leave-active {
        position: absolute;
        left: 0;
        right: 0;
    }
</style>
<style lang="less">
    .map-tip {
        padding: 12px 16px;
        width: 200px;
        background: url(../assets/tip-border.png) center no-repeat;
        background-size: 100% 100%;
        color: #fff;
    }
    .map-tip-img {
        margin: 10px auto;
        height: 83px;
        background: center no-repeat;
        background-size: cover;
    }
    .map-tip-title {
        font-weight: bold;
    }
    .map-tip-addr {
        display: flex;
        font-size: 12px;
        white-space: normal;
    }
    .map-tip-addr-text {
        flex: 1;
    }
</style>
